// extracted by mini-css-extract-plugin
export var blogCategory = "BlogCategory__blogCategory__QjYJN";
export var blogCategoryContainer = "BlogCategory__blogCategoryContainer__uojF_";
export var categoryIntro = "BlogCategory__categoryIntro__W_96E";
export var column = "BlogCategory__column__o313R";
export var container = "BlogCategory__container__l7eNf";
export var current = "BlogCategory__current__ROSaw";
export var link = "BlogCategory__link__zhSDO";
export var pagination = "BlogCategory__pagination__QR2zu";
export var row = "BlogCategory__row__MgHMH";
export var sidebarContainer = "BlogCategory__sidebarContainer__t1Z6B";
export var spinnerContainer = "BlogCategory__spinnerContainer__krpbo";
export var topCategories = "BlogCategory__topCategories__Bq_Re";
export var topContainer = "BlogCategory__topContainer__tCcpI";